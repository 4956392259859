import React, { useEffect, useRef } from 'react';
import { LoadingButton } from 'components/shared/LoadingButton';
import { Formik, FormikHelpers } from 'formik';
import { Button, Form, Modal } from 'react-bootstrap';
import * as yup from 'yup';
import { t } from 'i18next';
import { handleHttpResponseError } from 'components/shared/helpers';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { editBucket } from 'services/buckets/operations';
import { Bucket } from '../types';

interface EditBucketModalProps {
  show: boolean;
  bucket: Bucket;
  close: () => void;
}

const validationSchema = yup.object().shape({
  monthlyQty: yup.number().integer(t('wholeQtyOnly')).min(0),
});

interface FormValues {
  monthlyQty: number | undefined;
}

const EditBucketModal = ({ show, bucket, close }: EditBucketModalProps) => {
  const initialValues = {
    monthlyQty: bucket.monthlyQty,
  };
  const dispatch = useDispatch<Dispatch<any>>();
  const inputRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (show && inputRef.current) {
      inputRef.current.focus();
    }
  }, [show]);

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting, setFieldError }: FormikHelpers<FormValues>,
  ): Promise<any> => {
    setSubmitting(true);
    try {
      const bucketInfos: Partial<Bucket> = {
        monthlyQty: values.monthlyQty ? values.monthlyQty : bucket.monthlyQty,
      };

      await editBucket(bucket.id, bucketInfos)(dispatch);

      setSubmitting(false);
      close();
    } catch (err) {
      handleHttpResponseError(err, 'FAILED EDIT BUCKET', setFieldError);
    }
    setSubmitting(false);
  };

  return (
    <Modal show={show} onHide={close}>
      <Modal.Header>
        <Modal.Title>
          {t('editBucket', {
            position: bucket.position,
          })}
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, handleBlur, handleSubmit, handleChange, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              <Form.Group className="mb-3" controlId="monthlyQty">
                <Form.Label>{t('monthlyQty')}</Form.Label>
                <Form.Control
                  type="number"
                  name="monthlyQty"
                  value={values.monthlyQty}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={bucket.monthlyQty?.toString()}
                  isInvalid={!!errors.monthlyQty}
                  ref={inputRef}
                />
                <Form.Control.Feedback type="invalid">{errors.monthlyQty}</Form.Control.Feedback>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <LoadingButton
                variant="primary"
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                {t('submit')}
              </LoadingButton>
              <Button variant="secondary" onClick={close}>
                {t('close')}
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditBucketModal;
