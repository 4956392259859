import { handleHttpResponseError } from 'components/shared/helpers';
import { Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import * as yup from 'yup';
import './style.scss';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'services/store';
import ResourcesHandler from 'components/ResourcesHandler';
import { fetchOrganizations } from 'services/organizations/operations';
import { LoadingButton } from 'components/shared/LoadingButton/LoadingButton';
import { t } from 'i18next';
import { importBucketList } from 'services/buckets/operations';

const validationSchema = yup.object().shape({
  organizationName: yup.string().required('Required'),
  bucketList: yup.string().required('Required'),
});

interface FormValues {
  organizationName: string;
  bucketList: any;
}

const initialValues = {
  organizationName: '',
  bucketList: null,
};

interface ImportBucketListModalProps {
  show: boolean;
  close: () => void;
}

const ImportBucketListModal = ({ show, close }: ImportBucketListModalProps) => {
  const dispatch = useDispatch<Dispatch<any>>();
  const organizations = useSelector((state: RootState) => state.organizations);
  const [errorJson, setErrorJson] = useState<boolean>(false);

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting, setFieldError }: FormikHelpers<FormValues>,
  ): Promise<any> => {
    setSubmitting(true);
    setErrorJson(false);
    try {
      await importBucketList(JSON.parse(values.bucketList), values.organizationName)(dispatch);
      setSubmitting(false);
      close();
    } catch (err) {
      handleHttpResponseError(err, 'FAILED UPLOAD BEST SELLERS', setFieldError);
      setErrorJson(true);
    }
    setSubmitting(false);
  };

  const getModal = () => (
    <Modal show={show} onHide={close} className="bucket-list-modal">
      <Modal.Header>
        <Modal.Title>{t('importBestSellers')}</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Body className="form-body">
              <Form.Group controlId="organizationName">
                <Form.Label>{t('organizations')}:</Form.Label>
                <select
                  name="organizationName"
                  onChange={(e) => setFieldValue('organizationName', e.target.value)}
                  className={
                    errors.organizationName && touched.organizationName ? 'error-select' : ''
                  }
                >
                  <option>Aucune</option>
                  {organizations.map((o) => (
                    <option value={o.name}>{o.name}</option>
                  ))}
                </select>
                {errors.organizationName && touched.organizationName && (
                  <span className="error-message">{t('chooseOrganization')}</span>
                )}
              </Form.Group>
              <Form.Group controlId="bucketList">
                <Form.Label>{t('bestSellers')}:</Form.Label>
                <input
                  className={
                    errors.bucketList && touched.bucketList
                      ? 'form-control error-file'
                      : 'form-control'
                  }
                  id="bucketList"
                  name="bucketList"
                  type="file"
                  accept=".json"
                  onBlur={handleBlur}
                  onChange={(event) => {
                    const file = event.target?.files?.[0];
                    const reader = new FileReader();
                    reader.onload = (event) => {
                      setFieldValue('bucketList', event.target?.result);
                    };
                    if (file) {
                      reader.readAsText(file);
                    } else {
                      console.error('No file');
                    }
                  }}
                />
                {errors.bucketList && touched.bucketList && (
                  <span className="error-message">{t('chooseJSONfile')}</span>
                )}
              </Form.Group>
            </Modal.Body>
            {errorJson && <span className="error-message">{t('jsonContentError')}</span>}
            <Modal.Footer>
              <LoadingButton
                variant="primary"
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                {t('submit')}
              </LoadingButton>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );

  return (
    <ResourcesHandler
      resources={[organizations]}
      resourceFetchers={[() => dispatch(fetchOrganizations())]}
      getContents={getModal}
    />
  );
};

export default ImportBucketListModal;
