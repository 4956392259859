import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'services/store';
import {
  DrugOrderExcludedReason,
  DrugOrderStatus,
  Order,
  OrderStatus,
  OrderType,
} from 'components/orders/types';
import { Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import {
  cancelOngoing,
  archiveOrder,
  restoreOrder,
  sendCompletedMessage,
  triggerOrder,
  patchOrder,
  reloadCubiOrder,
} from 'services/orders/endpoints';
import { UserPermissions } from 'components/users/types';
import { PrescriptionSoftware } from 'components/organizations/types';
import { useCheckPermissions } from 'utils/permissions';
import { Actions, Warnings } from './ButtonsCell';
import { verifyRefillAndExpired } from './MainButtons';

interface SecondaryButtonsProps {
  order: Order;
  status: OrderType | undefined;
  setWarning: (warning: Warnings) => void;
  setAction: (action: Actions) => void;
  setToRefill: (refills: string[]) => void;
  setExpiredDrugs: (expiredDrugs: string[]) => void;
}

const SecondaryButtons = ({
  order,
  status,
  setWarning,
  setAction,
  setToRefill,
  setExpiredDrugs,
}: SecondaryButtonsProps) => {
  const user = useSelector((state: RootState) => state.login);
  const organizations = useSelector((state: RootState) => state.organizations);
  const prescriptionSoftware = organizations.find(
    (org) => org.id === user.organizationId,
  )?.prescriptionSoftware;
  const isArchived: boolean = status === OrderType.ARCHIVED;
  const hasVAssystPermission: boolean = useCheckPermissions(UserPermissions.MACHINES_V_ASSYST);
  const noDinCubiOrder: boolean =
    !!prescriptionSoftware &&
    prescriptionSoftware.name === PrescriptionSoftware.Cubi &&
    order.drugs.some((drug) =>
      drug.distributions.some(
        (dist) => dist.excludedReason === DrugOrderExcludedReason.DIN_UNAVAILABLE,
      ),
    );

  switch (order.status) {
    case OrderStatus.PENDING:
      return (
        <DropdownButton id="dropdown-basic-button" title="">
          {!hasVAssystPermission && <SendManuallyItem order={order} setWarning={setWarning} />}
          {hasVAssystPermission && (
            <LinkToTrayItem
              order={order}
              setWarning={setWarning}
              setAction={setAction}
              setToRefill={setToRefill}
              setExpiredDrugs={setExpiredDrugs}
            />
          )}
          {!isArchived && <ArchiveItem order={order} />}
          {order.progression && order.progression.nbCardsProduced && (
            <CompleteNowItem order={order} />
          )}
          {noDinCubiOrder && <ReloadCubiOrderItem order={order} />}
        </DropdownButton>
      );

    case OrderStatus.INTRAY:
      return (
        <DropdownButton id="dropdown-basic-button" title="">
          <SendManuallyItem order={order} setWarning={setWarning} />
        </DropdownButton>
      );

    case OrderStatus.ONGOING:
      return (
        <>
          <CancelButton order={order} />
        </>
      );

    case OrderStatus.FAILED:
      return (
        <>
          <DropdownButton id="dropdown-basic-button" title="">
            <RestoreItem order={order} />
            {!isArchived && <ArchiveItem order={order} />}
          </DropdownButton>
        </>
      );
    case OrderStatus.COMPLETED:
      return (
        <>
          {(!isArchived || hasVAssystPermission) && (
            <DropdownButton id="dropdown-basic-button" title="">
              {hasVAssystPermission && <SendVAssystItem order={order} />}
              {!isArchived && <ArchiveItem order={order} />}
            </DropdownButton>
          )}
        </>
      );
    case OrderStatus.CANCELLED:
    case OrderStatus.UNPROCESS:
      return (
        <>
          {!isArchived && (
            <DropdownButton id="dropdown-basic-button" title="">
              <ArchiveItem order={order} />
            </DropdownButton>
          )}
        </>
      );
    default:
      return <></>;
  }
};

interface ButtonProps {
  order: Order;
}

const ArchiveItem = ({ order }: ButtonProps) => (
  <Dropdown.Item
    variant="danger"
    onClick={() => {
      archiveOrder(order.id);
    }}
  >
    <FontAwesomeIcon icon="archive" /> {t('archive')}
  </Dropdown.Item>
);

const SendVAssystItem = ({ order }: ButtonProps) => (
  <Dropdown.Item
    onClick={() => {
      sendCompletedMessage(order.id);
    }}
  >
    <FontAwesomeIcon icon="paper-plane" /> {t('sendVAssyst')}
  </Dropdown.Item>
);

const RestoreItem = ({ order }: ButtonProps) => {
  const handleRestore = async () => {
    await restoreOrder(order.id);
  };
  return (
    <Dropdown.Item onClick={handleRestore}>
      <FontAwesomeIcon icon="reply" /> {t('restore')}
    </Dropdown.Item>
  );
};

interface LinkToTrayProps extends ButtonProps {
  setWarning: (warning: Warnings) => void;
  setAction: (action: Actions) => void;
  setToRefill: (refills: string[]) => void;
  setExpiredDrugs: (expiredDrugs: string[]) => void;
}

const LinkToTrayItem = ({
  order,
  setWarning,
  setAction,
  setToRefill,
  setExpiredDrugs,
}: LinkToTrayProps) => {
  const hasUndistributedExclusion: boolean = order.drugs.some((drug) =>
    drug.distributions.some(
      (dist) => dist.isExcluded && dist.status === DrugOrderStatus.UNDISTRIBUTED && !drug.isFlagged,
    ),
  );
  const buckets = useSelector((state: RootState) => state.buckets);

  const handleLinking = async () => {
    await verifyRefillAndExpired(
      order,
      buckets,
      setAction,
      setWarning,
      setToRefill,
      setExpiredDrugs,
    );
  };
  return (
    <Dropdown.Item disabled={hasUndistributedExclusion} onClick={handleLinking}>
      <FontAwesomeIcon icon="inbox" /> {t('link')}
    </Dropdown.Item>
  );
};

const CancelButton = ({ order }: ButtonProps) => (
  <Button variant="danger" onClick={() => cancelOngoing(order.id)}>
    <FontAwesomeIcon icon="times" />
  </Button>
);

interface ManualSendProps extends ButtonProps {
  setWarning: (warning: Warnings) => void;
}
const SendManuallyItem = ({ order, setWarning }: ManualSendProps) => {
  const sendOrder = async (order: Order) => {
    const twoWeeksInMilliseconds = 14 * 24 * 60 * 60 * 1000;
    const twoWeeksPriorToday = new Date(Date.now() - twoWeeksInMilliseconds).toISOString();

    if (order.newestOrder) {
      setWarning(Warnings.NOT_NEW);
    } else if (order.createdAt < twoWeeksPriorToday) {
      setWarning(Warnings.OLDER_TWO_WEEKS);
    } else {
      await triggerOrder(order.id, order.machineId);
    }
  };

  const hasMachineDistribution: boolean = order.drugs.some((drug) =>
    drug.distributions.some((d) => !d.isExcluded),
  );

  return (
    <Dropdown.Item
      disabled={!hasMachineDistribution}
      variant="primary"
      onClick={() => sendOrder(order)}
    >
      <FontAwesomeIcon icon="paper-plane" /> {t('send')}
    </Dropdown.Item>
  );
};

const CompleteNowItem = ({ order }: ButtonProps) => (
  <Dropdown.Item
    variant="primary"
    onClick={() =>
      patchOrder(order.id, {
        status: OrderStatus.COMPLETED,
        nbCards: order.progression?.nbCardsProduced,
      })
    }
  >
    <FontAwesomeIcon icon="check" />{' '}
    {t('completeAsXCards', { nbCards: order.progression?.nbCardsProduced })}
  </Dropdown.Item>
);

const ReloadCubiOrderItem = ({ order }: ButtonProps) => (
  <Dropdown.Item variant="primary" onClick={() => reloadCubiOrder(order.id)}>
    <FontAwesomeIcon icon="upload" /> {t('updateCubiOrder')}
  </Dropdown.Item>
);

export default SecondaryButtons;
