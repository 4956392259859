import React, { useRef, useState } from 'react';
import { t } from 'i18next';
import { Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DrugForce } from '../types';
import '../style.scss';

interface DrugForceFieldsProps {
  setFieldValue: (fieldName: string, value: string) => void;
}

const DrugForceFields = ({ setFieldValue }: DrugForceFieldsProps) => {
  const [hasSecondForce, setHasSecondForce] = useState<boolean>(false);
  const [firstInvalid, setFirstInvalid] = useState<boolean>(false);
  const [secondInvalid, setSecondInvalid] = useState<boolean>(false);
  const firstForce = useRef<HTMLInputElement>(null);
  const firstForceUnit = useRef<HTMLSelectElement>(null);
  const secondForce = useRef<HTMLInputElement>(null);
  const secondForceUnit = useRef<HTMLSelectElement>(null);

  const handleChange = () => {
    setFirstInvalid(false);
    setSecondInvalid(false);
    const regex = /^[0-9.,]+$/;

    if (firstForce?.current?.value && !regex.test(firstForce.current.value)) {
      setFirstInvalid(true);
    }

    if (hasSecondForce && secondForce?.current?.value && !regex.test(secondForce.current.value)) {
      setSecondInvalid(true);
    }

    if (firstInvalid || secondInvalid) {
      return;
    }

    const firstForceCombined = `${firstForce?.current?.value}${firstForceUnit?.current?.value}`;
    const secondForceCombined = hasSecondForce
      ? ` / ${secondForce?.current?.value}${secondForceUnit?.current?.value}`
      : '';
    setFieldValue('force', `${firstForceCombined}${secondForceCombined}`);
  };

  return (
    <div className="drug-force-field">
      <Form.Group>
        <Form.Label>{t('strength')}</Form.Label>
        <div className="force-field-row">
          <div className="force-field-input">
            <Form.Control
              onChange={handleChange}
              id="firstForce"
              type="text"
              ref={firstForce}
              isInvalid={firstInvalid}
            />
            <Form.Control.Feedback type="invalid">{t('invalidForceField')}</Form.Control.Feedback>
          </div>
          <div className="force-field-unit">
            <Form.Control
              onChange={handleChange}
              as="select"
              name="firstForceUnit"
              ref={firstForceUnit}
            >
              {DrugForce.map((form) => (
                <option key={form} value={form}>
                  {form}
                </option>
              ))}
            </Form.Control>

            {!hasSecondForce && (
              <Button onClick={() => setHasSecondForce(true)}>
                <FontAwesomeIcon icon="plus" />
              </Button>
            )}
          </div>
        </div>

        {hasSecondForce && (
          <div className="force-field-row">
            <div className="force-field-input">
              <Form.Control
                onChange={handleChange}
                id="secondForce"
                type="text"
                ref={secondForce}
                isInvalid={secondInvalid}
              />
              <Form.Control.Feedback type="invalid">{t('invalidForceField')}</Form.Control.Feedback>
            </div>
            <div className="force-field-unit">
              <Form.Control
                onChange={handleChange}
                as="select"
                name="secondForceUnit"
                ref={secondForceUnit}
              >
                {DrugForce.map((form) => (
                  <option key={form} value={form}>
                    {form}
                  </option>
                ))}
              </Form.Control>
              <Button onClick={() => setHasSecondForce(false)}>
                <FontAwesomeIcon icon="minus" />
              </Button>
            </div>
          </div>
        )}
      </Form.Group>
    </div>
  );
};

export default DrugForceFields;
