import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Organization, OrganizationStatus } from './types';
import './style.scss';

interface OrganizationRowProps {
  organization: Organization;
  handleShowOrganizationDetails: (organization: Organization) => void;
  handleShowOrganizationForm: (organization: Organization) => void;
  handleDelete: (organization: Organization) => void;
}

const OrganizationRow = ({
  organization,
  handleShowOrganizationDetails,
  handleShowOrganizationForm,
  handleDelete,
}: OrganizationRowProps): JSX.Element => (
  <tr className="organizationRow">
    <td className="organization-status-cell">
      {organization.status === OrganizationStatus.UNDER_MAINTENANCE && (
        <FontAwesomeIcon icon="wrench" size="1x" />
      )}
      {organization.status === OrganizationStatus.ACTIVE && (
        <FontAwesomeIcon icon="check-circle" color="green" size="1x" />
      )}
    </td>
    <td className="tableCell-expand">
      <Button variant="link" onClick={() => handleShowOrganizationDetails(organization)}>
        {organization.name}
      </Button>
    </td>
    <td className="tableCell--narrow">
      <Button variant="outline-secondary" onClick={() => handleShowOrganizationForm(organization)}>
        <FontAwesomeIcon icon="pen" />
      </Button>
    </td>
    <td className="tableCell--narrow">
      <Button variant="outline-danger" onClick={() => handleDelete(organization)}>
        <FontAwesomeIcon icon="trash" />
      </Button>
    </td>
  </tr>
);

export default OrganizationRow;
