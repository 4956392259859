import React, { ChangeEvent, useState } from 'react';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'services/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Form, Table } from 'react-bootstrap';
import { deleteOrganization } from 'services/organizations/operations';
import ConfirmationModal from 'components/shared/ConfirmationModal/ConfirmationModal';
import OrganizationRow from './OrganizationRow';
import OrganizationDetailsModal from './OrganizationDetailsModal/OrganizationDetailsModal';
import OrganizationFormModal from './OrganizationFormModal';
import { Organization } from './types';
import './style.scss';

function OrganizationsTable(): JSX.Element {
  const dispatch = useDispatch<Dispatch<any>>();
  const { t } = useTranslation();
  const organizations = useSelector((state: RootState) => state.organizations);

  const [showOrganizationDetails, setShowOrganizationDetails] = useState<boolean>(false);
  const [showOrganizationForm, setShowOrganizationForm] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [invalidSearch, setInvalidSearch] = useState<boolean>(false);
  const machines = useSelector((state: RootState) => state.machines);
  const [selectedOrganization, setSelectedOrganization] = useState<Organization | null>(null);

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>): void => {
    setInvalidSearch(false);
    const regex = /^[a-zA-Z0-9]+$/;
    if (regex.test(e.target.value) || !e.target.value) {
      setSearch(e.target.value);
    } else {
      setInvalidSearch(true);
    }
  };

  const handleDeleteOrganization = async () => {
    try {
      if (selectedOrganization) await deleteOrganization(selectedOrganization.id)(dispatch);
    } catch (err) {
      console.error(err);
    } finally {
      setSelectedOrganization(null);
      setShowConfirmationModal(false);
    }
  };

  const getOrganizationDeleteConfirmationMessage = () => {
    const organizationMachines =
      machines && selectedOrganization
        ? machines.filter((machine) => machine.organizationId === selectedOrganization.id)
        : [];
    return (
      <div style={{ textAlign: 'center' }}>
        {organizationMachines.length > 0 && <p>{t('deleteOrganizationMachinesWarning')}</p>}
        {organizationMachines.map((machine) => (
          <p key={machine.id} style={{ color: 'red' }}>
            <strong>{machine.name}</strong>
          </p>
        ))}
        <p>{t('deleteOrganizationConfirmation')}</p>
      </div>
    );
  };

  const rows =
    organizations &&
    organizations
      .filter(
        (organization) => !search || organization.name.toLowerCase().includes(search.toLowerCase()),
      )
      .sort((organizationA, organizationB) => (organizationA.name < organizationB.name ? -1 : 1))
      .map((organization) => (
        <OrganizationRow
          key={organization.id}
          organization={organization}
          handleShowOrganizationDetails={(organization) => {
            setShowOrganizationDetails(true);
            setSelectedOrganization(organization);
          }}
          handleShowOrganizationForm={(organization) => {
            setShowOrganizationForm(true);
            setSelectedOrganization(organization);
          }}
          handleDelete={(organization) => {
            setShowConfirmationModal(true);
            setSelectedOrganization(organization);
          }}
        />
      ));

  return (
    <>
      <div style={{ position: 'relative', width: '75%' }}>
        <Form.Control
          type="text"
          onChange={handleChangeSearch}
          placeholder={`${t('search')}...`}
          isInvalid={invalidSearch}
        />
        <Form.Control.Feedback type="invalid">{t('invalidSearchQuery')}</Form.Control.Feedback>
        <FontAwesomeIcon
          icon="search"
          color="black"
          style={{
            background: 'transparent',
            border: 'none',
            cursor: 'pointer',
            display: 'inline-block',
            fontSize: 20,
            position: 'absolute',
            top: 10,
            right: 12,
            zIndex: 2,
          }}
        />
      </div>
      <br />
      <Table responsive bordered striped>
        <thead>
          <tr>
            <th>{t('status')}</th>
            <th>{t('name')}</th>
            <th colSpan={2}>{t('actions')}</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      {selectedOrganization && (
        <>
          <OrganizationDetailsModal
            show={showOrganizationDetails && selectedOrganization !== null}
            close={() => {
              setSelectedOrganization(null);
              setShowOrganizationDetails(false);
            }}
            organization={selectedOrganization}
          />
          <OrganizationFormModal
            show={showOrganizationForm}
            close={() => {
              setSelectedOrganization(null);
              setShowOrganizationForm(false);
            }}
            organization={selectedOrganization}
          />
        </>
      )}
      <ConfirmationModal
        show={showConfirmationModal && selectedOrganization !== null}
        onHide={() => {
          setSelectedOrganization(null);
          setShowConfirmationModal(false);
        }}
        onConfirm={handleDeleteOrganization}
        message={getOrganizationDeleteConfirmationMessage()}
      />
    </>
  );
}

export default OrganizationsTable;
