import { OrganizationStatus } from 'components/organizations/types';
import { SuperTechPermissions } from 'components/users/types';
import { useSelector } from 'react-redux';
import { RootState } from 'services/store';

export function useCheckPermissions(requiredPermission: string): boolean {
  const loginState = useSelector((state: RootState) => state.login);
  const userPermissions = loginState && loginState.permissions;
  return !!userPermissions?.includes(requiredPermission);
}

export function checkIsAdmin() {
  const loginState = useSelector((state: RootState) => state.login);
  return loginState.organizationId === '*';
}

export function checkIsSuperTech() {
  const loginState = useSelector((state: RootState) => state.login);
  const userPermissions = loginState && loginState.permissions;
  const asEveryPermissions = SuperTechPermissions.every((permission) =>
    userPermissions?.includes(permission),
  );
  return asEveryPermissions && loginState.organizationId !== '*';
}

export function getOrganizationStatus(
  organizationId: string | undefined,
): OrganizationStatus | undefined {
  const organizations = useSelector((state: RootState) => state.organizations);
  if (organizationId && organizations) {
    return organizations.find((o) => o.id === organizationId)?.status;
  }
  return undefined;
}

export default {
  useCheckPermissions,
  checkIsAdmin,
  checkIsSuperTech,
};
