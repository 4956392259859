import React, { useEffect, useRef, useState } from 'react';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import Modal from 'components/UI/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { editQuantity } from 'services/buckets/operations';
import { focusInput } from 'utils/forms';
import { Button } from 'react-bootstrap';
import { Drug } from 'components/drugs/types';
import { handleHttpResponseError } from 'components/shared/helpers';
import { Bucket } from './types';

interface FormValues {
  quantity: number;
  justification: string;
}

const initialValues: FormValues = {
  quantity: 0,
  justification: '',
};

interface EditQuantityModalProps {
  isOpen: boolean;
  bucket: Bucket;
  drug: Drug | undefined;
  onClose: () => void;
}

function EditQuantityModal({ isOpen, bucket, drug, onClose }: EditQuantityModalProps) {
  const dispatch = useDispatch<Dispatch<any>>();
  const { t } = useTranslation();
  const quantityFieldRef = useRef<HTMLInputElement>(null);
  const schema = yup.object().shape({
    quantity: yup
      .number()
      .required(t('pleaseEnterQuantity'))
      .integer(t('wholeQtyOnly'))
      .min(0, t('quantityCantBeNegative'))
      .max(5000, t('quantityMustBeMax5000')),
    justification: yup
      .string()
      .trim()
      .matches(/^[0-9a-zA-Zà-üÀ-Ü\s.',-]+$/, t('invalidSymbolInText'))
      .required(t('pleaseEnterJustification')),
  });

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting, setFieldError }: FormikHelpers<FormValues>,
  ): Promise<any> => {
    try {
      await editQuantity(bucket.id, values.quantity, values.justification)(dispatch);
      setSubmitting(false);
      onClose();
    } catch (err) {
      handleHttpResponseError(err, 'FAILED TO EDIT BUCKET QUANTITY', setFieldError);
    }
  };

  const [variation, setVariation] = useState<number>(0);
  const calculVariation = () => {
    const quantityEntered = parseInt(quantityFieldRef?.current?.value as string, 10);
    setVariation(quantityEntered - bucket.quantity);
  };

  useEffect(() => {
    if (isOpen) {
      quantityFieldRef.current?.focus();
    }
  }, [isOpen]);

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      {({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <Modal
          show={isOpen}
          modalClass="editQuantityModal"
          onHide={onClose}
          onSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          disabledSubmitButton={false}
          title={t('editQuantity', {
            position: bucket.position,
            DIN: bucket.DIN,
            drugName: drug ? drug.name.toUpperCase() : '-',
          })}
        >
          <div
            style={{
              textAlign: 'left',
            }}
          >
            <span>
              {t('actualQuantity')} {bucket.quantity}
            </span>
            <hr />
            <Form
              id="EditQuantity_form"
              noValidate
              onSubmit={(e) => {
                if (Object.keys(errors).length > 0) {
                  focusInput(Object.keys(errors)[0]);
                }
                handleSubmit(e);
              }}
            >
              <span
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              >
                <Form.Group className="mb-3" controlId="quantity" style={{ width: '80%' }}>
                  <Form.Label>{t('realQuantity')}</Form.Label>
                  <Form.Control
                    type="number"
                    name="quantity"
                    ref={quantityFieldRef}
                    value={values.quantity}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder={`${t('enterQuantity')} 0-5000`}
                    isInvalid={!!errors.quantity}
                  />
                  <Form.Control.Feedback type="invalid">{errors.quantity}</Form.Control.Feedback>
                </Form.Group>{' '}
                <span style={{ paddingTop: '15px' }}>
                  <Button
                    variant="outline-secondary"
                    onClick={calculVariation}
                    style={{ marginLeft: '8px', alignSelf: 'center' }}
                  >
                    <FontAwesomeIcon icon="sync" color="dark-grey" />
                  </Button>{' '}
                  Variation : {variation}
                </span>
              </span>
              <Form.Group className="mb-3" controlId="justification">
                <Form.Label>{t('justification')}</Form.Label>
                <Form.Control
                  type="text"
                  name="justification"
                  value={values.justification}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isInvalid={!!errors.justification}
                />
                <Form.Control.Feedback type="invalid">{errors.justification}</Form.Control.Feedback>
              </Form.Group>
            </Form>
          </div>
        </Modal>
      )}
    </Formik>
  );
}

export default EditQuantityModal;
