import {
  UserRole,
  UserPermissions,
  TechPermissions,
  SuperTechPermissions,
  AdminPermisssions,
} from './types';

export function getUserRole(permissions: UserPermissions[]): UserRole {
  if (AdminPermisssions.every((permission) => permissions.includes(permission))) {
    return UserRole.ADMIN;
  }
  if (SuperTechPermissions.every((permission) => permissions.includes(permission))) {
    return UserRole.SUPER_TECH;
  }
  if (TechPermissions.every((permission) => permissions.includes(permission))) {
    return UserRole.TECH;
  }

  return UserRole.UNKNOWN;
}
