import React, { useEffect, useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import ConfirmationModal from 'components/shared/ConfirmationModal/ConfirmationModal';
import { DateTime } from 'luxon';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { deleteMachine } from 'services/machines/operations';
import { getActiveOrderSummary } from 'services/machines/endpoints';
import { OrderStatus } from 'components/orders/types';
import { Machine } from '../../machines/types';

interface OrganizationMachinesTableProps {
  organizationId: string;
  machines: Machine[];
}

function OrganizationMachinesTable({
  organizationId,
  machines,
}: OrganizationMachinesTableProps): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch<Dispatch<any>>();
  const [ordersLoading, setOrdersLoading] = useState<boolean>(false);
  const [machineToDelete, setMachineToDelete] = useState<Machine | null>(null);
  const [inTrayOrderCount, setInTrayOrderCount] = useState<number>(0);
  const [onGoingOrderCount, setOnGoingOrderCount] = useState<number>(0);

  useEffect(() => {
    if (machineToDelete) {
      setOrdersLoading(true);

      const fetchActiveOrders = async () => {
        try {
          const orders = await getActiveOrderSummary(machineToDelete.id);
          const counts = orders?.reduce(
            (acc, order) => {
              if (order.status === OrderStatus.INTRAY) acc.intrayCount += 1;
              else if (order.status === OrderStatus.ONGOING) acc.ongoingCount += 1;
              return acc;
            },
            { intrayCount: 0, ongoingCount: 0 },
          );
          setInTrayOrderCount(counts?.intrayCount ?? 0);
          setOnGoingOrderCount(counts?.ongoingCount ?? 0);
        } catch (err) {
          console.error(err);
        } finally {
          setOrdersLoading(false);
        }
      };

      fetchActiveOrders();
    }
  }, [machineToDelete]);

  const resetStates = () => {
    setMachineToDelete(null);
    setInTrayOrderCount(0);
    setOnGoingOrderCount(0);
  };

  const handleDeleteMachine = async () => {
    try {
      if (machineToDelete) {
        await deleteMachine(machineToDelete.id)(dispatch);
      }
      resetStates();
    } catch (err) {
      console.error(err);
    }
  };

  const machineDeleteConfirmationMessage = (
    <>
      {inTrayOrderCount > 0 && (
        <p style={{ color: 'red' }}>
          <strong>
            {t('deleteMachineOrderAlert', {
              count: inTrayOrderCount,
              status: OrderStatus.INTRAY,
            })}
          </strong>
        </p>
      )}
      {onGoingOrderCount > 0 && (
        <p style={{ color: 'red' }}>
          <strong>
            {t('deleteMachineOrderAlert', {
              count: onGoingOrderCount,
              status: OrderStatus.ONGOING,
            })}
          </strong>
        </p>
      )}
      <p>{t('deleteMachineConfirmation')}</p>
    </>
  );

  const rows =
    machines &&
    machines
      .filter((machine) => machine.organizationId === organizationId)
      .map((machine) => {
        const formatedDate = DateTime.fromISO(machine.lastStatusReceivedAt);
        return (
          <tr key={machine.id}>
            <td>{machine.name}</td>
            <td>{machine.status}</td>
            <td>{formatedDate.toFormat('yyyy-MM-dd HH:mm:ss')}</td>
            <td>
              <Button
                size="sm"
                onClick={() => {
                  setMachineToDelete(machine);
                }}
                variant="outline-danger"
              >
                <FontAwesomeIcon size="sm" icon="trash" />
              </Button>
            </td>
          </tr>
        );
      });

  return (
    <>
      <Table responsive bordered striped>
        <thead>
          <tr>
            <th>{t('name')}</th>
            <th>{t('status')}</th>
            <th>{t('lastUpdatedOn')}</th>
            <th>{t('actions')}</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      <ConfirmationModal
        show={machineToDelete !== null && !ordersLoading}
        onHide={resetStates}
        onConfirm={handleDeleteMachine}
        message={machineDeleteConfirmationMessage}
      />
    </>
  );
}
export default OrganizationMachinesTable;
