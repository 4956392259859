import React, { useState } from 'react';
import { Order } from 'components/orders/types';
import { Formik } from 'formik';
import { addTray, getTrayOrder, removeTray } from 'services/orders/endpoints';
import * as yup from 'yup';
import { Button, Form } from 'react-bootstrap';
import { t } from 'i18next';
import { handleHttpResponseError } from 'components/shared/helpers';
import AlreadyLinkedAlert from '../AlreadyLinkedAlert';
import './style.scss';

interface FormValues {
  trayId: string | undefined;
}

const initialValues: FormValues = {
  trayId: undefined,
};

interface AddTrayFormProps {
  order: Order;
  setCompleted: () => void;
}

interface FormSubmitProps {
  values: FormValues;
  setSubmitting: (isSubmitting: boolean) => void;
  setFieldError: (field: string, message: string | undefined) => void;
}

const AddTrayForm = ({ order, setCompleted }: AddTrayFormProps) => {
  const [alreadyLinked, setAlreadyLinked] = useState<{ order: Order; trayId: string } | null>(null);
  const [unlinkFailed, setUnlinkFailed] = useState<boolean>(false);

  const unlinkAndAdd = async () => {
    try {
      await Promise.all([
        removeTray(alreadyLinked!.order.id, alreadyLinked!.trayId),
        addTray(order.id, alreadyLinked!.trayId),
        setCompleted(),
      ]);
      setAlreadyLinked(null);
    } catch (err) {
      console.error('FAILED TO UNLINK AND ADD', err);
      setUnlinkFailed(true);
    }
  };

  const formSubmit = async ({ values, setSubmitting, setFieldError }: FormSubmitProps) => {
    setSubmitting(true);
    setAlreadyLinked(null);
    setUnlinkFailed(false);

    const inTray = await getTrayOrder(values.trayId!).then((res) => res.order);

    if (!inTray) {
      try {
        await Promise.all([addTray(order.id, values.trayId!), setCompleted()]);
        setSubmitting(false);
      } catch (err) {
        handleHttpResponseError(err, 'FAILED TO ADD TRAY', setFieldError);
      }
    } else {
      setAlreadyLinked({ order: inTray, trayId: values.trayId! });
    }

    setSubmitting(false);
  };

  const schema = yup.object().shape({
    trayId: yup
      .string()
      .matches(/^[0-9]+$/, t('fieldOnlyAcceptNumbers'))
      .required(),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        formSubmit({ values, setSubmitting, setFieldError });
      }}
    >
      {({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="trayId">
            <Form.Label>{t('addTray_4cards')}</Form.Label>
            <Form.Control
              type="text"
              name="trayId"
              autoFocus
              value={values.trayId}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={!!errors.trayId}
            />
            <Form.Control.Feedback type="invalid">{errors.trayId}</Form.Control.Feedback>
          </Form.Group>
          {alreadyLinked && (
            <AlreadyLinkedAlert linkedOrder={alreadyLinked.order} nextAction={unlinkAndAdd} />
          )}
          {unlinkFailed && <p className="error-message">{t('encounterError')}</p>}
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            {t('submit')}
          </Button>{' '}
          <Button onClick={setCompleted}> {t('cancel')}</Button>
        </Form>
      )}
    </Formik>
  );
};

export default AddTrayForm;
