import { OrderFailureStatus, CardMap, DrugOrder } from './types';

export function failureStatusText(failureStatus: OrderFailureStatus): string {
  switch (failureStatus) {
    case 'BUSY':
      return 'Occupée';
    case 'FAILURE':
      return 'Erreur';
    default:
      return 'Inconnue';
  }
}

export function getDailyDistribution(cardMap: CardMap, day: number) {
  return cardMap.map((time) => time[day]);
}

export const emptyCardMap = (): CardMap => [
  ['', '', '', '', '', '', ''],
  ['', '', '', '', '', '', ''],
  ['', '', '', '', '', '', ''],
  ['', '', '', '', '', '', ''],
];

export const calculateTotalQuantity = (drugOrder: DrugOrder) => {
  const cardQty = (cardMap: CardMap) =>
    cardMap.reduce(
      (sum, row) => sum + row.reduce((rowSum, drugQty) => rowSum + parseInt(drugQty, 10), 0),
      0,
    );

  const quantity = drugOrder.distributions.reduce((sum, distribution) => {
    if (distribution.isExcluded) {
      return 0;
    }
    return sum + cardQty(distribution.cardMap);
  }, 0);
  return quantity;
};
