import { handleHttpResponseError } from 'components/shared/helpers';
import { Formik } from 'formik';
import { t } from 'i18next';
import React, { useEffect, useRef } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { toggleFlaggedDrug } from 'services/orders/endpoints';
import * as yup from 'yup';

interface FlagModalProps {
  show: boolean;
  orderId: string;
  drugDIN: string;
  close: () => void;
}

interface FormValues {
  message: string;
}

const initialValues: FormValues = {
  message: '',
};

interface FormSubmitProps {
  values: FormValues;
  setSubmitting: (isSubmitting: boolean) => void;
  setFieldError: (field: string, message: string | undefined) => void;
}

const FlagModal = ({ show, orderId, drugDIN, close }: FlagModalProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (show && inputRef.current) {
      inputRef.current.focus();
    }
  }, [show]);

  const formSubmit = async ({ values, setSubmitting, setFieldError }: FormSubmitProps) => {
    setSubmitting(true);

    try {
      await toggleFlaggedDrug(orderId, drugDIN, values.message);
      setSubmitting(false);
      close();
    } catch (err) {
      handleHttpResponseError(err, 'FAILED TO FLAG', setFieldError);
    }

    setSubmitting(false);
  };

  const schema = yup.object().shape({
    message: yup.string().required(),
  });

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>{t('flag')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting, setFieldError }) => {
            formSubmit({ values, setSubmitting, setFieldError });
          }}
        >
          {({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="message">
                <Form.Label>{t('enterReason')}</Form.Label>
                <Form.Control
                  ref={inputRef}
                  type="text"
                  name="message"
                  value={values.message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.message}
                />
                <Form.Control.Feedback type="invalid">{errors.message}</Form.Control.Feedback>
              </Form.Group>
              <Modal.Footer>
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  {t('submit')}
                </Button>{' '}
                <Button variant="secondary" onClick={close}>
                  {t('cancel')}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default FlagModal;
