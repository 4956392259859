import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'services/store';
import {
  useCheckPermissions,
  checkIsAdmin,
  checkIsSuperTech,
  getOrganizationStatus,
} from 'utils/permissions';
import links from 'utils/links';
import Page401 from 'pages/errorPages/Page401';
import MaintenancePage from 'pages/errorPages/MaintenancePage';
import { OrganizationStatus } from './organizations/types';

interface PrivateRouteProps {
  component: React.ComponentType<any>;
  path: string;
  requiredPermission: string;
  isAdminOnly?: boolean;
  isAdminOrSuperTech?: boolean;
}

const PrivateRoute = ({
  component: Component,
  path,
  requiredPermission,
  isAdminOnly = false,
  isAdminOrSuperTech = false,
  ...rest
}: PrivateRouteProps) => {
  const login = useSelector((state: RootState) => state.login);
  const lackPermission = requiredPermission && !useCheckPermissions(requiredPermission);
  const isAdmin = checkIsAdmin();
  const isSuperTech = checkIsSuperTech();
  const unauthorized =
    lackPermission || (isAdminOnly && !isAdmin) || (isAdminOrSuperTech && !isAdmin && !isSuperTech);
  const orgInMaintenance =
    getOrganizationStatus(login.organizationId) === OrganizationStatus.UNDER_MAINTENANCE;

  return (
    <Route
      {...rest}
      path={path}
      render={(props) => {
        if (!login) {
          return (
            <Redirect
              to={{
                pathname: links.paths.login,
                state: { from: props.location },
              }}
            />
          );
        }
        if (unauthorized) {
          return <Page401 />;
        }
        if (orgInMaintenance) {
          return <MaintenancePage />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
