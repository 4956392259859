import React, { SyntheticEvent, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { Form } from 'react-bootstrap';
import { checkIsAdmin } from 'utils/permissions';
import { useSelector } from 'react-redux';
import { RootState } from 'services/store';
import { OrderTableOptions } from './OrdersTable';
import '../style.scss';

interface SearchBarProps {
  loadOrders: (options: OrderTableOptions) => void;
  options: OrderTableOptions;
  setOptions: (options: OrderTableOptions) => void;
}

const SearchBar = ({ options, setOptions, loadOrders }: SearchBarProps) => {
  const organizations = useSelector((state: RootState) => state.organizations);
  const isAdmin = checkIsAdmin();
  const [currentQuery, setCurrentQuery] = useState<string>('');
  const [invalidSearch, setInvalidSearch] = useState<boolean>(false);

  const handleOrgViewChange = (orgId: string) => {
    loadOrders({ ...options, query: currentQuery, organizationView: orgId });
    setOptions({ ...options, organizationView: orgId });
  };

  const handleChangeSearch = (
    e:
      | SyntheticEvent<HTMLInputElement, FocusEvent>
      | SyntheticEvent<HTMLInputElement, KeyboardEvent>,
  ) => {
    const newQuery = e.currentTarget.value;

    const regex = /^[0-9a-zA-Zà-üÀ-Ü\s.',-]+$/;
    setInvalidSearch(false);
    if (newQuery && !regex.test(newQuery)) {
      setInvalidSearch(true);
      return;
    }

    if (newQuery !== currentQuery) {
      if (e.nativeEvent instanceof KeyboardEvent) {
        if (e.nativeEvent.key === 'Enter') {
          loadOrders({ ...options, query: newQuery });
          setCurrentQuery(newQuery);
        }
      } else {
        loadOrders({ ...options, query: newQuery });
        setCurrentQuery(newQuery);
      }
    }
  };

  return (
    <div className="order-filters">
      <div className="search-bar">
        <div className="form-control-and-error">
          <Form.Control
            type="text"
            onBlur={handleChangeSearch}
            placeholder={`${t('search')}...`}
            isInvalid={invalidSearch}
            onKeyUp={handleChangeSearch}
          />
          <Form.Control.Feedback type="invalid">{t('invalidSearchQuery')}</Form.Control.Feedback>
        </div>
        <FontAwesomeIcon
          icon="search"
          color="black"
          style={{
            background: 'transparent',
            border: 'none',
            cursor: 'pointer',
            display: 'inline-block',
            fontSize: 20,
            position: 'absolute',
            top: '0.5rem',
            right: '0.5rem',
            zIndex: 2,
          }}
        />{' '}
      </div>{' '}
      {isAdmin && (
        <select
          className="organization-view"
          name="organizationName"
          onChange={(e) => handleOrgViewChange(e.target.value)}
        >
          <option value="">Toutes</option>
          {organizations.map((o) => (
            <option value={o.id}>{o.name}</option>
          ))}
        </select>
      )}
    </div>
  );
};

export default SearchBar;
