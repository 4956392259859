import React from 'react';
import { Order, OrderType } from 'components/orders/types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { TableColumn } from 'react-data-table-component';
import i18n from 'i18next';
import { ButtonsCell } from './Cells/ButtonsCell';
import DateCell from './Cells/DateCell';
import CardsOptions from './Cells/CardsOptions/CardsOptions';
import { OrderStatusCell } from './Cells/StatusCell/OrderStatusCell';
import PatientCell from './Cells/PatientCell';

export function orderColumns(status: OrderType | undefined): TableColumn<Order>[] {
  return [
    {
      id: 'lookupId',
      name: '#',
      selector: (o) => (o.lookupId.length >= 5 ? `${o.lookupId.slice(-3)}` : o.lookupId),
      sortable: true,
      grow: 0,
      maxWidth: '100%',
      minWidth: '75px',
    },
    {
      id: 'createdAt',
      name: i18n.t('printDate'),
      sortable: true,
      cell: (order) => <DateCell date={order.createdAt} />,
      grow: 2,
    },

    {
      id: 'progression.completedOn',
      name: i18n.t('completedOn'),
      sortable: true,
      omit: status === OrderType.ACTIVE || status === undefined, // eslint-disable-line
      cell: (order) => <DateCell date={order.progression?.completedOn} />,
      grow: 2,
    },
    {
      id: 'patient',
      name: i18n.t('patient'),
      cell: (order) => <PatientCell order={order} />,
      selector: (o) => o.patient,
      sortable: true,
      grow: 4.5,
    },
    {
      id: 'foyer',
      name: 'Foyer',
      cell: (o) => {
        const firstFiveWords = o.foyer ? o.foyer.split(' ').slice(0, 5).join(' ') : '';
        const displayText = firstFiveWords === o.foyer ? firstFiveWords : `${firstFiveWords}...`;
        return o.foyer !== firstFiveWords ? (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id={`tooltip-${o.id}`}>{o.foyer}</Tooltip>}
          >
            <span>{displayText}</span>
          </OverlayTrigger>
        ) : (
          <span>{displayText}</span>
        );
      },
      sortable: true,
      grow: 3,
    },
    {
      id: 'cardsOptions',
      name: i18n.t('cards'),
      sortable: false,
      grow: 4,
      cell: (order) => <CardsOptions order={order} />,
      minWidth: '250px',
    },
    {
      id: 'status',
      name: i18n.t('status'),
      grow: 4,
      cell: (order) => <OrderStatusCell order={order} />,
      sortable: true,
    },
    {
      id: 'buttons',
      name: '',
      grow: 4,
      cell: (order) => <ButtonsCell order={order} status={status} />,
      sortable: false,
    },
  ];
}
