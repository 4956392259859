import { Bucket, DeactivatedReason, Lot } from 'components/buckets/types';
import { get, post, put, patch, del } from '../api';

export const getBuckets = (filter) => get('/buckets', {}, { filter });

export const getBucket = (id: string) => get('/buckets/:id', { id });

export const createBucket = (bucket: Bucket) => post('/buckets', {}, bucket);

export const importBucketList = (bucketList, orgName: string) =>
  post('/buckets/importBucketList/:orgName', { orgName }, bucketList);

export const updateBucket = (id: string, bucket: Bucket) => put('/buckets/:id', { id }, bucket);

export const patchBucket = (id: string, bucket: Partial<Bucket>) =>
  patch('/buckets/:id', { id }, bucket);

export const deleteBucket = (id: string) => del('/buckets/:id', { id });

export const loadBucket = () => post('/buckets/load/');

export const unloadBucket = (id: string) => post('/buckets/:id/unload', { id });

export const activateBucket = (id: string) => post('/buckets/:id/activate', { id });

export const deactivateBucket = (id: string, reason: DeactivatedReason) =>
  post('/buckets/:id/deactivate', { id }, { reason });

export const addLot = (bucketId: string, lots: Lot[]) =>
  post('/buckets/:id/lots', { id: bucketId }, lots);

export const emptyLots = (bucketId: string) => post('/buckets/:id/emptylots', { id: bucketId });

export const editQuantity = (bucketId: string, newQuantity: number, justification: string) =>
  put('/buckets/:id/editQuantity', { id: bucketId }, { newQuantity, justification });

export const editBucket = (bucketId: string, bucketInfos: Partial<Bucket>) =>
  put('/buckets/:id/editBucket', { id: bucketId }, { bucketInfos });

export const inRefillList = (bucketId: string, isIn: boolean) =>
  put('/buckets/:id/inRefillList', { id: bucketId }, { isIn });
