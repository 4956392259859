import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'services/store';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { changeNbCards } from 'services/orders/endpoints';
import { PrescriptionSoftware } from 'components/organizations/types';
import { nbCards, Order, OrderStatus } from '../../../types';
import OneCardIcon from './OneCardIcon';
import './style.scss';
import TwoCardsIcon from './TwoCardsIcon';
import ThreeCardsIcon from './ThreeCardsIcon';
import FourCardsIcon from './FourCardsIcon';

interface CardsOptionsProps {
  order: Order;
}

function CardsOptions({ order }: CardsOptionsProps) {
  const organizations = useSelector((state: RootState) => state.organizations);
  const prescriptionSoftware = organizations.find(
    (org) => org.id === order.organizationId,
  )?.prescriptionSoftware;
  const [selected, setSelected] = useState<nbCards>(order.barcodes.length as nbCards);
  const cantClick =
    order.status === OrderStatus.INTRAY ||
    (!!prescriptionSoftware && prescriptionSoftware.name === PrescriptionSoftware.Cubi);

  useEffect(() => {
    setSelected(order.barcodes.length as nbCards);
  }, [order.barcodes.length]);

  const handleNbCardChange = async (value) => {
    setSelected(value);
    await changeNbCards(order.id, value);
  };

  return (
    <div className={cantClick ? 'noClick cardsOptions' : 'cardsOptions'}>
      <ToggleButtonGroup type="radio" name="options" value={selected} onChange={handleNbCardChange}>
        <ToggleButton value={1}>
          <div className="TrayIconContainer">
            <OneCardIcon selected={selected === 1} disabled={cantClick} />
          </div>
        </ToggleButton>
        <ToggleButton value={2}>
          <div className="TrayIconContainer">
            <TwoCardsIcon selected={selected === 2} disabled={cantClick} />
          </div>
        </ToggleButton>
        <ToggleButton value={3}>
          <div className="TrayIconContainer">
            <ThreeCardsIcon selected={selected === 3} disabled={cantClick} />
          </div>
        </ToggleButton>
        <ToggleButton value={4}>
          <div className="TrayIconContainer">
            <FourCardsIcon selected={selected === 4} disabled={cantClick} />
          </div>
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}

export default CardsOptions;
